<template>
  <div>
    <p class="nodata"  v-if="aData.length==0">暂无数据</p>
    <column-list :set="false" :oData="listData" @changePage="handleChangePage"></column-list>
  </div>
</template>
<script>
import ColumnList from "../../teams/components/Columnlist";
import { specialGatherRequest } from "@/api/project.js";
export default {
  components: {
    ColumnList
  },
  data() {
    return {
      listData:{},
      searchinp:'',
      aData:[]
    };
  },
   watch:{
    $route: {
        handler(newName, oldName) {
          if (this.$route.query.wd) {
              this.searchinp=newName.query.wd
          }
           this.getList();
          return
        },
        immediate: true
      }

  },
  methods: {
    async getList(val) {
      try {
        val = val || "1";
        var oData;
        if(this.searchinp){
           oData={
            page: val,
            key: this.searchinp
          }
        }else{
          oData={
            page: val
          }
        }
        const result = await specialGatherRequest(oData);
        if (result.status == 200) {
          this.listData = result.data;
          this.aData= result.data.list
        }
      } catch (err) {}
    },
    handleChangePage(val) {
      this.getList(val);
    }
  }
};
</script>
<style  lang="less" scoped>
.nodata{
    margin: 0 auto;
    padding: 100px 0 50px;
    text-align: center;
    color: #888;
}
</style>